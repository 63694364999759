<template>
    <div class="table table-tribe-details">
        <div class="table-head">
            <div class="tr">
                <div class="tr-data-wrap">
                    <div class="tr-main-data">
                        <div class="td">
                            <span>Elves:</span>
                        </div>
                        <div class="td">
                            <span>Power level:</span>
                        </div>
                        <div class="td">
                            <span>Cumulative # of seeds</span>
                        </div>
                        <div class="td">
                            <span>Cumulative sales</span>
                        </div>
                        <div class="td">
                            <span>Referrers reward</span>
                        </div>
                    </div>

                    <div class="tr-actions-data">
                        <div class="td">
                            <span>ACTIONS</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="tribeData && tribeData.length" class="table-body">
            <!-- removed active class as not data show-up -->
            <div v-for="tribe in tribeData" class="tr">
                <div class="tr-data-wrap">
                    <div class="tr-main-data">
                        <div class="td">
                            <span class="td-mobile td-mobile-title">Elves:</span>
                            <span>{{ makeShort(tribe.elveAddress) }}</span>
                        </div>
                        <div class="td">
                            <span class="td-mobile td-mobile-title">Power level:</span>
                            <span>Lvl {{ tribe.salesLevel }}</span>
                        </div>
                        <div class="td td-value">
                            <span class="td-mobile td-mobile-title">Cumulative # of seeds</span>
                            <span class="td-value">
                                <i class="coin-icon"></i>
                                {{ tribe.totalPositionAmount }} BNB
                            </span>
                        </div>
                        <div class="td">
                            <span class="td-mobile td-mobile-title">Cumulative sales</span>
                            <span class="td-value">
                                <i class="coin-icon"></i>
                                {{ tribe.sales }} BNB
                            </span>
                        </div>
                        <div class="td">
                            <span class="td-mobile td-mobile-title">Referral reward</span>
                            <span class="td-value">
                                <i class="coin-icon"></i>
                                {{ getReferralReward(tribe) }} BNB
                            </span>
                        </div>
                    </div>

                    <div class="tr-actions-data">
                        <div class="td">
                            <button @click="claimReferralReward(tribe.referrer)" class="btn btn-collect">Collect sales</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="table-body">
            <div class="tr tr-no-data">
                <div>
                    <p>No data yet</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup></script>

<script>
    export default {
        props: ["tribeData", "userData"],
        name: "TribeDetails",
        data() {
            return {};
        },
        methods: {
            async claimReferralReward(address) {
                alert("Smart contract method to be invoked");
                // try {
                //     let tx = await this.$root.core.claimReferrerReward(address);
                //     this.$store.commit("push_notification", {
                //         type: "warning",
                //         typeClass: "warning",
                //         message: `Processing your transaction...`,
                //     });
                //     await tx.wait(3);
                //     this.$store.commit("push_notification", {
                //         type: "success",
                //         typeClass: "success",
                //         message: `Your transaction has been completed.`,
                //     });
                // } catch (error) {
                //     console.log(error);
                //     this.$root.core.handleError(error);
                // }
            },
            makeShort(addr) {
                if (addr) {
                    return addr.slice(0, 5) + "..." + addr.slice(addr.length - 5, addr.length);
                }
            },
            getReferralReward(tribe) {
                if (!this.tribeData.length || !this.userData) return 0;
                return parseFloat(Number(((tribe.sales * 0.06 * 80) / 100) * this.userData.salesLevel).toFixed(8));
            },
        },
    };
</script>

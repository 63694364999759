<template>
    <button @click="batchAction()" class="btn-batch-action">Batch action</button>
</template>

<script>
    export default {
        props: ["boxes", "boxType", "epoch", "actionType", "addresses"],
        methods: {
            async batchAction() {
                if (this.actionType === "batchClaimPositionIncentiveReward") {
                    try {
                        const indexes = this.boxes.map((el) => el.index);
                        let tx = await this.$root.core.batchClaimPositionIncentiveReward(this.boxType, this.epoch, indexes);
                        this.$store.commit("push_notification", {
                            type: "warning",
                            typeClass: "warning",
                            message: `Processing your transaction...`,
                        });
                        await tx.wait(3);
                        this.$store.commit("push_notification", {
                            type: "success",
                            typeClass: "success",
                            message: `Your transaction has been completed.`,
                        });
                    } catch (error) {
                        console.log(error);
                        this.$root.core.handleError(error);
                    }
                } else if (this.actionType === "batchClosePositions") {
                    try {
                        const indexes = this.boxes.map((el) => el.index);
                        let tx = await this.$root.core.batchClosePositions(this.boxType, this.epoch, indexes);
                        this.$store.commit("push_notification", {
                            type: "warning",
                            typeClass: "warning",
                            message: `Processing your transaction...`,
                        });
                        await tx.wait(3);
                        this.$emit("updateComponent");
                        this.$store.commit("push_notification", {
                            type: "success",
                            typeClass: "success",
                            message: `Your transaction has been completed.`,
                        });
                    } catch (error) {
                        console.log(error);
                        this.$root.core.handleError(error);
                    }
                } else if (this.actionType === "batchReportSales") {
                    try {
                        let tx = await this.$root.core.batchReportSales(this.addresses);
                        this.$store.commit("push_notification", {
                            type: "warning",
                            typeClass: "warning",
                            message: `Processing your transaction...`,
                        });
                        await tx.wait(3);
                        this.$store.commit("push_notification", {
                            type: "success",
                            typeClass: "success",
                            message: `Your transaction has been completed.`,
                        });
                    } catch (error) {
                        console.log(error);
                        this.$root.core.handleError(error);
                    }
                }
            },
        },
    };
</script>

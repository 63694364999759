<template>
    <div class="tribe-details">
        <section class="section-filter">
            <div class="container">
                <div class="row row-filter">
                    <BatchAction :actionType="'batchReportSales'" :addresses="getUserReferrals" />
                </div>
            </div>
        </section>
        <section class="section-table">
            <div class="container">
                <TribeDetails :tribeData="tribeData" :userData="userData" />
            </div>
        </section>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import BatchAction from "../../components/BatchAction.vue";
    import TribeDetails from "../../components/BoxData/TribeDetails.vue";

    export default {
        data() {
            return {
                intervalUpdateId: 0,
            };
        },
        components: { BatchAction, TribeDetails },
        computed: {
            ...mapState(["userData", "tribeData"]),
            getUserReferrals() {
                if (!this.userData) return [];
                return this.userData.userRefData.childrens;
            },
        },
        mounted() {
            if (!this.tribeData) {
                const intervalId = setInterval(async () => {
                    console.log("start interval check");
                    if (this.userData) {
                        clearInterval(intervalId);
                        console.log("found, interval cleared");
                        const res = await this.$root.core.getTribeDetails(this.getUserReferrals);
                        this.$store.commit("setTribeData", res);
                        this.startUpdateLoop();
                    }
                }, 300);
            } else {
                this.startUpdateLoop();
            }
        },
        methods: {
            startUpdateLoop() {
                this.intervalUpdateId = setInterval(async () => {
                    console.log("start interval update check");
                    if (this.userData) {
                        const res = await this.$root.core.getTribeDetails(this.getUserReferrals);
                        this.$store.commit("setTribeData", res);
                        console.log("data updated");
                    }
                }, 15000);
            },
        },

        beforeDestroy() {
            console.log("clear interval");
            clearInterval(this.intervalUpdateId);
        },
    };
</script>
